<template>
  <main>
    <AboutUsShort/>
      <!-- <AboutUsLong/> -->
      <OurSectors/>
      <OurProjects/>
      <UsingTechnologies/>
      <OurTeam/>
      <!-- <ContactUs/> -->
  </main>
</template>

<script>
import AboutUsShort from '@/components/homePage/AboutUsShort.vue'
// import AboutUsLong from '@/components/homePage/AboutUsLong.vue'
import OurSectors from '@/components/homePage/OurSectors.vue'
import OurProjects from '@/components/homePage/OurProjects.vue'
import UsingTechnologies from '@/components/homePage/UsingTechnologies.vue'
import OurTeam from '@/components/homePage/OurTeam.vue'
// import ContactUs from '@/components/homePage/ContactUs.vue'

export default {
  name: 'DS360HomeView',
  components: {
    AboutUsShort,
    // AboutUsLong,
    OurSectors,
    OurProjects,
    UsingTechnologies,
    OurTeam,
    // ContactUs
  },
  mounted()
  {
    document.title = "DS360—Broader perspective on Data Science"
    const meta = document.getElementsByTagName('meta')
    meta.description.content = "Wdrażamy zaawansowane i nowatorskie rozwiązania biznesowe, które odpowiadają potrzebom nawet najbardziej wymagających klientów."
  }
}
</script>
