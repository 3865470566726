<template>
    <transition name="fade">
        <div class="loader-wrapper" v-if="isloaded">
            <video class="loading-video" autoplay muted loop playsinline>
                <source src="@/assets/animations/ds360_loading_1.mp4" type='video/mp4'>
            </video>
        </div>
    </transition>   
</template>

<script>
export default {
    data()
    {
        return{
            isloaded: true
        }
        
    },
    mounted()
    {
        this.showLoading()   
    },
    methods:{
        showLoading(){
            setTimeout(()=>
            {
                this.isloaded = false
            }, 1500)
        },
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        }
    }
}
</script>