/* eslint-disable no-console */

import { register } from 'register-service-worker'

//to (if) należy odkomentować, jeżeli puszczamy wersję z tagiem
if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}



// const urlsToCache = [
//   "/media/",
//   "/img/", 
//   "/css/app.8fef89df.css", 
//   "/img/bg13.ce8bfeb0.svg", 
//   "/img/oracle.aec1a4bf.svg", 
//   "/media/ds360_loading_1.c58eb31f.mp4", 
//   "/img/d_pieciul.dffdad8e.webp",
//   "/img/cryptoDS360.7cc85d08.svg",
//   "/img/logo-solo.80f6b162.svg",
//   "/img/l_terpilowski_2.7a9eddb4.webp",
//   "/img/datascience.109ccd11.svg",
//   "/img/boss.924d6430.svg",
//   "/img/simulate.cbdac3cd.svg",
//   "/img/m_fender.c2619660.webp",
//   "/img/d_kaszynski.10745630.webp",
//   "/img/software.38a2b56d.svg",
//   "/img/m_korecka.4950d94e.webp",
//   "/img/analytics.ac1221c1.svg",
//   "/img/u_bialonczyk.0dc46cd9.webp",
//   "/img/springboot2.4ab887f8.svg",
//   "/img/plsql2.39db7edf.svg",
//   "/img/crypto_logo_2.1f1c4de9.svg",
//   "/img/relations.67756420.svg",
//   "/img/img2.7b60c86d.svg",
//   "/img/img1.a91e5189.svg",
//   "/img/bg14.3991a2ef.svg",
//   "/img/gk.c0cf7ba4.svg",
//   "/img/python.ea049dd7.svg",
//   "/img/sql.a34336f6.svg",
//   "/img/java.7c43da89.svg",
//   "/img/bg2.cd24703d.svg",
//   "/img/bg4.f7da7202.svg",
//   "/img/bg15.6ab4b038.svg",
//   "/img/dart.9c7a28df.svg",
//   "/img/mail.45a61d25.svg",
//   "/img/img8.34838ba0.svg",
//   "/img/flutter.be6cb28c.svg",
//   "/img/img6.bf0cdaec.svg",
//   "/img/bg1_1.8a961f34.svg",
//   "/img/img3.c2319e28.svg",
//   "/img/img7.cb53c9b3.svg",
//   "/img/business_office.32be5d0e.svg",
//   "/img/img5.1f9f8ca5.svg",
//   "/img/img4.b5afbdba.svg"
// ];