export default {
  "NavHeader": {
    "sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obszary"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekty"])},
    "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół"])}
  },
  "AboutUsShort": {
    "mainHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szersze <br>spojrzenie <br>na Data Science"])},
    "mainDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizujemy projekty związane ze Sztuczną Inteligencją end-to-end. Zajmujemy się pełnym cyklem życia modelu, od pozyskania i analizy danych, poprzez wdrożenie i monitorowanie produkcyjnego działania modelu. W naszej pracy łączymy najlepsze praktyki z dwóch światów: dynamicznego sektora komercyjnego oraz rygorystycznych standardów akademickich. Dzięki temu dostarczamy rozwiązania, które nie tylko spełniają oczekiwania biznesowe, ale także są oparte na metodach naukowych."])}
  },
  "AboutUsLong": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nas"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteśmy zespołem ekspertów z pasją do całej branży IT i ML. W naszej pracy wykorzystujemy najnowsze technologie i niebanalne rozwiązania. Doradzamy firmom z całego świata, rozwijamy oprogramowanie, w którym pracują i sprawiamy, że robią to lepiej. Nie stoimy w miejscu, ciągle działamy i otwieramy się na innowacje. Jesteśmy zgranym zespołem i chcemy, żebyś stał się jednym z nas. "])}
  },
  "OurSectors": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obszary, w których działamy"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanowimy zespół ekspertów specjalizujący się w rozwiązanych wykorzystujących Machine Learning. Realizujemy podejście end-to-end obejmujące pełny cykl życia projektu Data Science: od zbierania danych, poprzez ich przetwarzanie, analizę, modelowanie, aż po wdrożenie i monitorowanie wyników. W naszej pracy wykorzystujemy najnowsze technologie, aby dostarczać naszym klientom produkty najwyższej jakości. Świadczymy usługi doradcze firmom z całego świata. Jesteśmy dynamiczni i otwarci na innowacje."])},
    "sector9_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakość danych"])},
    "sector9_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekty w obszarze utrzymania jakości danych skupiają się na kompleksowym przetwarzaniu i analizie informacji, co umożliwia naszym klientom skupienie się na najważniejszych aspektach ich działalności. Stosujemy techniki walidacji, czyszczenia i wzbogacania danych, aby zapewnić ich integralność i wiarygodność. Dzięki tym zabiegom nasi klienci mogą pracować na spójnych, kompletnych i wiarygodnych danych wykorzystywanych w bieżącym zarządzaniu i strategicznym planowaniu."])},
    "sector10_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza danych"])},
    "sector10_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zbieramy, przetwarzamy i analizujemy dane z wykorzystaniem algorytmów, narzędzi statystycznych oraz technik Machine Learning. Nasze podejście obejmuje eksplorację danych, identyfikację wzorców oraz tworzenie modeli predykcyjnych, które dostarczają spersonalizowanych insightów i rekomendacji. Wspieramy naszych klientów w przejściu: dane, prawidłowość, decyzja. Przekształcamy często surowe dane produkcyjne w użyteczne informacje, które wspierają procesy decyzyjne na każdym szczeblu funkcjonowania organizacji."])},
    "sector11_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelowanie"])},
    "sector11_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiadamy kompetencje w obszarze optymalizacji matematycznej, symulacji oraz modeli prognostycznych, które wykorzystywane są przez naszych klientów w ich działalności gospodarczej. W zależności od kontekstu potrzeb klienta, dostarczamy szyte na miarę rozwiązania sztucznej inteligencji wspierającej procesy planistyczne organizacji (np. optymalizacja logistyki, symulacyjne scenariusze What-if lub prognoza popytu dla sektora retail)."])},
    "sector12_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wdrażanie modeli AI w organizacjach"])},
    "sector12_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrujemy zaawansowane modele AI w procesy organizacji, pomagając firmom w pełni wykorzystać potencjał sztucznej inteligencji. Nasze usługi obejmują dostosowanie modeli do specyficznych potrzeb biznesowych, szkolenie zespołów oraz zapewnienie ciągłego wsparcia w zakresie monitorowania i optymalizacji. Efektywne wdrożenie AI, w naszej ocenie musi uwzględniać: ludzi, procesy i technologie."])}
  },
  "OurProjects": {
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencja"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasze projekty"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doświadczenie przekuwamy w sukces naszych klientów."])},
    "number1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
    "number1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["projektów klienckich"])},
    "number2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
    "number2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["projektów badawczych"])},
    "number3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30"])},
    "number3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mln budżetu"])},
    "crypto_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CryptoDS360"])},
    "crypto_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CryptoDS360 to narzędzie do analizy twoich zysków i strat. Pozwala ono śledzić aktywności największych kont jak i przeglądanie szerokiej gamy statystyk."])},
    "crypto_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dowiedz się więcej"])},
    "project1_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opracowanie algorytmu trendu i predykcji"])},
    "project1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt związany z opracowaniem oprogramowania wykorzystującego algorytmy do wyceny nieruchomości. W ramach projektu opracowywane były algorytmy trendów rynkowych, prognozy ceny nieruchomości jako formy zabezpieczenia (np. kredyt hipoteczny). Prace modelarskie realizowaliśmy w Javie przy wykorzystaniu Spring Data w celu utworzenia mapowania obiektowo-relacyjnego."])},
    "project2_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opracowanie modelu skoringu kredytowego"])},
    "project2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt obejmował opracowanie modelu skoringu kredytowego klientów wykorzystywanego przez banki. W ramach projektu przeprowadziliśmy analizę predyktywności zmiennych objaśniających, wraz z identyfikacją anomalii w danych (np. problemy współliniowości, nieliniowości lub interakcji między zmiennymi). Opracowany model podlegał – zgodnie z Rekomendacją W KNF – niezależnemu przeglądowi przed wdrożeniem na produkcję."])},
    "project3_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model predykcji występowania szkodników oraz pleśni"])},
    "project3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt polegał na realizacji modelu predykcji, które obejmowało szczegółową analizę przedmiotu, zdefiniowanie matematycznego modelu umozliwiającego klasyfikację wieloklasową i implementację algorytmu w Pythonie."])},
    "project4_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migracja modelu\nskoringu kredytowego"])},
    "project4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W ramach prac opracowaliśmy system informatyczny umożliwiający kompilację algorytmów oceny zdolności kredytowych opracowanych w języku SAS na standard formatu PMML. Standard ten umożliwia integrację z rozwiązaniami stworzonymi w innych technologiach – Python, R lub Julia. Kompilacji i przeniesieniu między standardami podlegały procesy transformacji danych, estymacji modeli  oraz przeprowadzania prognoz."])},
    "project5_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doradztwo dla Ministerstwa Zdrowia RP w zakresie ilościowej syntezy informacji statystycznej"])},
    "project5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt zrealizowany dla Ministerstwa Zdrowia RP obejmował realizację usług doradczych z zakresu ilościowej syntezy informacji statystycznej oraz wsparcie koncepcyjne i merytoryczne. Doradztwo obejmowało obszar Mapy potrzeb zdrowotnych – Baza Analiz Systemowych i Wdrożeniowych."])},
    "project6_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model długoterminowego zapotrzebowania na gaz sieciowy wysokometanowy"])},
    "project6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W trakcie wykonania projektu określiliśmy główne determinanty wpływające na zapotrzebowanie gazu. Przygotowaliśmy metodykę prognozowania popytu długoterminowo i rozwijaliśmy na jej podstawie system, który został zaimplementowany w R. Projekt zakończyliśmy, dostarczając szczegółową prognozę, która wykorzystuje przygotowany system, wartości bazowe parametrów i dane ilościowe."])},
    "project7_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eksperckie wsparcie w zakresie budowy zespołu i kompetencji w obszarze modeli predykcyjnych (Data Analytics)"])},
    "project7_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W projekcie skupiliśmy się na zdefiniowaniu profili kluczowych członków zespołu oraz oprogramowania niezbędnego do zarządzania danymi i budowy modeli prognostycznych. Następnie na ich podstawie mogliśmy określić scenariusze rozwoju, plan wdrożenia oraz zaproponować odpowiedni harmonogram prowadzonych prac."])},
    "project8_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szereg projektów\ni grantów B+R"])},
    "project8_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Członkowie naszego zespołu uczestniczyli w szeregu procesów pozyskiwania i realizacji projektów badawczych realizowanych ze środków publicznych. Projekty te obejmowały zagadnienia badań operacyjnych (tj. symulacja, optymalizacja, prognoza), przy wykorzystaniu współczesnych metod Machine Learning w procesach decyzyjnych. Łączna kwota budżetu projektów, w których wspieraliśmy naszych klientów  przekracza 100 mln PLN."])},
    "project_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naciśnij, aby rozwinąć"])}
  },
  "OurTechnologies": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie"])},
    "languages_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Języki programowania"])},
    "languages_others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oraz <br/> C, C#, LaTeX, JS, PHP, C++, Matlab, R, Kotlin, Julia, Fortran, CSS, HTML"])},
    "db_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bazy danych"])},
    "db_others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oraz <br/> PostgreSQL, H2, mySQL, Microsoft SQL Server"])},
    "frameworks_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frameworki"])},
    "frameworks_others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oraz <br/> Spring MVC, Spring Data, Jakarta EE, Junit 5, Django, Selenium, Cypres, Vue.js"])},
    "data_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obróbka danych"])},
    "visualizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wizualizacje"])},
    "forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prognoza"])},
    "simulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symulacje"])},
    "optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optymalizacja"])},
    "sql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQL"])},
    "r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])},
    "r_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R - dashboardy"])},
    "python": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Python"])},
    "python_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Python - dashboardy"])},
    "lib_machine_learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biblioteki uczenia maszynowego"])},
    "sym_monte_carlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symulacje Monte Carlo"])},
    "sym_multi-agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symulacje wieloagentowe"])},
    "prog_linear_int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie liniowe oraz programowanie całkowitoliczbowe"])},
    "opt_engines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silniki optymalizacyjne"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerstwo strategiczne"])},
    "partnership_scensei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scensei"])},
    "partnership_scensei_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jesteśmy partnerem strategicznym szwajcarskiej spółki Scensei GmbH działającej na rynku oprogramowania wykorzystywanego w obronności. Wraz z Scensei rozwijamy produkt OPTIMA umożliwiający stworzenie analityki generatywnych scenariuszy operacji obronnych realizowanych za pośrednictwem symulacji wieloagentowej opartej na modelach sztucznej inteligencji. Symulacje te umożliwiają analizę interakcji między jednostkami lądowymi, powietrznymi oraz systemami obrony i walki elektronicznej, podejmując decyzje w ramach sieci dowodzenia i kontroli."])}
  },
  "OurTeam": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasz zespół"])},
    "experts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół Ekspertów"])},
    "operationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół Operacyjny"])},
    "worker0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daniel Kaszyński"])},
    "worker0_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zarządzanie projektami"])},
    "worker0_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konsultant modeli analitycznych z dziesięcioletnim doświadczeniem. Wspierał największe na świecie banki we wdrażaniu algorytmów i modeli zarządzania ryzykiem. Daniel od wielu lat prowadzi zajęcia z metod analitycznych na SGH."])},
    "worker1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urszula Białończyk"])},
    "worker1_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza danych\nUczenie maszynowe"])},
    "worker1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analityczka specjalizująca się w szeroko pojętej analizie danych i modelowaniu predykcyjnym. W swojej karierze pracowała nad zagadnieniami związanymi m.in. z medycyną, transportem oraz bankowością. Doktorantka PAN zajmująca się modelowaniem medycznych baz danych."])},
    "worker2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dawid Pieciul"])},
    "worker2_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza danych\nZarządzanie zespołem"])},
    "worker2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analityk biznesowy z doświadczeniem przy migracji danych dużego systemu informatycznego, pasjonat rozwiązań IoT oraz łamigłówek logicznych. Dawid swoim koncepcyjnym podejściem potrafi odnaleźć optymalne rozwiązania różnych problemów."])},
    "worker3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakub Kot"])},
    "worker3_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafika komputerowa\nAnaliza danych"])},
    "worker3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktyk branży e-commerce, przez lata zajmujący się szeroko rozumianą modernizacją procesów sprzedażowych z perspektywy klienta komercyjnego. Od strony warsztatowej Kuba jest również grafikiem, doświadczonym na polu tworzenia materiałów użytkowych, designu UX oraz UI."])},
    "worker4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maciej Fender"])},
    "worker4_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie back-end\nTechnologie chmurowe"])},
    "worker4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maciej jest programistą back-end specjalizujący się w symulacjach wieloagentowych. W biznesie zajmuje się projektowaniem, tworzeniem, optymalizacją i wdrażaniem oprogramowania. Dodatkowo Maciej interesuje się rozwiązaniami chmurowymi i administracją."])},
    "worker5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dawid Dmitruk"])},
    "worker5_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie back-end\nSymulacje wieloagentowe"])},
    "worker5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java developer z doświadczeniem przy tworzeniu zarówno aplikacji webowych, jak i symulacji wieloagentowych. W swojej pracy zwraca on szczególną uwagę na jakość kodu oraz dba o jego optymalność."])},
    "worker6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bartłomiej Busłowski"])},
    "worker6_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie back-end\nMigracja danych"])},
    "worker6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bartłomiej to programista front-end z bogatym doświadczeniem jako programista baz danych dużego systemu informatycznego oraz analityk biznesowy. Prowadził także mały zespół developerski. Jego wszechstronne umiejętności techniczne i analityczne pozwalają mu na tworzenie intuicyjnych i wydajnych rozwiązań front-endowych."])},
    "worker7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agnieszka Rabiej"])},
    "worker7_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza danych\nUczenie maszynowe"])},
    "worker7_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analityk biznesowy, współpracujący z firmami m.in. z rynku energetycznego, mediowego oraz FMCG. Specjalizuje się w szeroko pojętej analizie i modelowaniu danych oraz wykorzystuje ML i AI we wspomaganiu procesów biznesowych."])},
    "worker8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracjan Ulianowski"])},
    "worker8_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie front-end\nProgramowanie back-end"])},
    "worker8_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracjan zajmuje się procesem tworzenia oraz wdrażania oprogramowania. Zdobywał doświadczenie w tworzeniu rozwiązań optymalizacyjnych dla klientów bankowych. Interesuje się zagadnieniami dotyczącymi IoT."])},
    "worker9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamil Kulesza"])},
    "worker9_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza danych\nUczenie maszynowe"])},
    "worker9_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analityk danych zajmujący się przetwarzaniem informacji, budową modeli analitycznych i automatyzacją procesów. Interesuje się praktycznymi zastosowaniami statystyki i uczenia maszynowego."])},
    "worker10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magdalena Korecka"])},
    "worker10_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafika komputerowa\nProgramowanie front-end"])},
    "worker10_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafik komputerowy i front-end developer. Posiada doświadczenie w tworzeniu stron internetowych oraz projektowaniu materiałów biznesowych i reklamowych."])},
    "worker11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łukasz Terpiłowski"])},
    "worker11_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie back-end\nProgramowanie front-end"])},
    "worker11_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full-Stack developer posiadający doświadczenie w zakresie React, Javascript, Java oraz Python. Łukasz pracował nad dostosowywaniem dużego modelu językowego (LLM) pod konkretne zastosowanie."])},
    "worker12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piotr Średnicki"])},
    "worker12_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie back-end\nProgramowanie front-end"])},
    "worker12_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programista Python, C, C++, oraz C#. Zajmował się tworzeniem aplikacji webowych oraz okienkowych. Piotr jest studentem informatyki na Politechnice Białostockiej."])},
    "worker13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milena Święcka"])},
    "worker13_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie back-end\nMigracja danych"])},
    "worker13_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milena specjalista ds. Data Governace z bogatym doświadczeniem jako programista baz danych dużego systemu informatycznego oraz analityk biznesowy. Milena posiada również doświadczenie w programowaniu front-end. W swoich aplikacjach koncentruje się na jakości wytwarzanego oprogramowania."])},
    "worker14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krzysztof Funkowski"])},
    "worker14_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie back-end\nMigracja danych"])},
    "worker14_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programista Full-stack oraz programista baz danych z bogatym doświadczeniem jako programista baz danych dużego systemu informatycznego. Krzysztof posiada doświadczenie w tworzeniu aplikacji webowych."])},
    "worker15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ewelina Kędzior"])},
    "worker15_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analiza danych\nUczenie maszynowe"])},
    "worker15_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programistka w języku Python oraz C#. Specjalizuje się w szeroko pojętej analizie i modelowaniu danych. Ewelina jest również analitykiem danych i interesuje się rozwojem algorytmów optymalizacyjnych."])},
    "worker16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piotr Romaniuk"])},
    "worker16_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie back-end\nUczenie maszynowe"])},
    "worker16_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piotr posiada doświadczenie w wielu językach programowania (Java, Python, C# i C++). Pracował z technologiami backendowymi, uczeniem maszynowym oraz silnikami gier komputerowych."])},
    "worker17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Michał Ciupa"])},
    "worker17_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programowanie front-end"])},
    "worker17_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programista z doświadczeniem w zakresie Typescript, Javascript, MongoDb. Michał zajmował się tworzeniem aplikacji webowych."])},
    "worker18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wit Jakuczun"])},
    "worker18_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doradca zarządu"])},
    "worker18_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wit doradza zespołom data science na poziomie warstwy zarządzającej. Specjalizuje się w łączeniu badań operacyjnych (constraint programming, mixed integer programming i metaheurystyka) z modelami predykcyjnymi w celu dostarczania rozwiązań wspomagających podejmowanie decyzji."])}
  },
  "ContactUs": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontaktuj się z nami!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potrzebujesz pomocy w rozwijaniu swojej firmy? Szukasz ciekawych przedsięwzięć? Chcesz nas lepiej poznać? Napisz do nas!"])},
    "company_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siedziba firmy:"])},
    "company_headline_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres e-mail:"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ul. Żurawia 71 <br/> 15-540 Bialystok <br/> Polska"])},
    "company_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tel.:"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail:"])},
    "placeholder_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj swój email..."])},
    "placeholder_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj swój numer kontaktowy..."])},
    "placeholder_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj temat wiadomości..."])},
    "placeholder_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napisz tutaj swoją wiadomość..."])},
    "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
    "recruitment_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcesz do nas dołączyć?"])},
    "recruitment_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prowadzimy aktywną rekrutację."])},
    "recruitment_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oferta rekrutacyjna"])}
  },
  "WebFooter": {
    "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS360 sp. z o. o. - wszelkie prawa zastrzeżone"])}
  },
  "WelcomeRecruitment": {
    "mainHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dołącz do zespołu <br>DS360!"])},
    "mainDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."])}
  },
  "WhatWeOffer": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co oferujemy?"])},
    "offer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesujące zagraniczne projekty (m.in. optymalizacja, symulacje wieloagentowe)"])},
    "offer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Współpracę z naukowcami z największych polskich uczelni"])},
    "offer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Płatny staż i elastyczne godziny pracy"])},
    "offer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możliwość nauki i rozwijania się"])}
  },
  "WhatWeWant": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Czego wymagamy?"])},
    "want1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajomość Javy lub Pythona"])},
    "want2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zainteresowanie zagadnieniami związanymi z Machine Learning"])},
    "want3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znajomość GITa"])},
    "want4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otwarty umysł i chęć rozwoju"])}
  },
  "SendCV": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdecydowany?"])},
    "desc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcesz zacząć z Nami współpracę i interesuje Cię wspólny rozwój? Wyślij do nas wiadomość lub prześlij swoje CV na podanego maila. Wkrótce się do Ciebie odezwiemy."])},
    "placeholder_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj swój email..."])},
    "placeholder_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj swój numer kontaktowy..."])},
    "placeholder_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaj temat wiadomości..."])},
    "placeholder_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napisz tutaj swoją wiadomość..."])},
    "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyślij"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Załącz CV"])}
  },
  "WelcomeCrypto": {
    "mainHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CryptoDS360"])},
    "mainDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CryptoDS360 pozwoli Ci lepiej analizować, zbierać wiedzę i inwestować w najbardziej popularne krypto waluty. Śledź bilans zysków i strat swoich inwestycji. Przeglądaj najnowsze, autorskie i godne zaufania analizy największych portfeli."])}
  },
  "CryptoScreens": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź, jak to wygląda! "])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzięki swojej przejrzystej formie, CryptoDS360 pozwala użytkownikom łatwo przełączać się między sekcjami. Jest to prosta w obsłudze aplikacja, która przyda się każdej osobie, której zależy na stałej kontroli swojego portfela kryptowalut."])},
    "swipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przesuń palcem, żeby zobaczyć więcej"])},
    "turn_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Włącz ciemny motyw"])},
    "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wyłącz ciemny motyw"])}
  },
  "CryptoFeatures": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Możliwości aplikacji"])},
    "feature1_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykresy cen"])},
    "feature1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najnowsze dane o cenach wybranych krypto walut."])},
    "feature2_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcje konta"])},
    "feature2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista transakcji wybranych kont."])},
    "feature3_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wykres transakcji danego dnia"])},
    "feature3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liczba transakcji wykonanych danego dnia."])},
    "feature4_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorska analiza blockchain"])},
    "feature4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przekształcenie surowych danych z blockchain do przejrzystej formy."])},
    "feature5_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorskie API"])},
    "feature5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasi eksperci zapewniają celne i wiarygodne źródło danych."])}
  },
  "ErrorPage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona, której szukasz nie istnieje."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naciśnij poniższy przycisk, żeby wrócić na stronę główną."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona główna"])}
  },
  "Alerts": {
    "success_contact_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiadomość w drodze!"])},
    "success_contact_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękujemy, za Twojego maila. Już niedługo nasz konsultant odpowie na Twoją wiadomość :)"])},
    "error_contact_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wystąpił błąd!"])},
    "error_contact_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Przepraszamy, wystąpił błąd wysyłania wiadomości."])},
    "success_sendcv_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dziękujemy, za udział w rekrutacji. Wyczekuj odpowiedzi zwrotnej ;)"])},
    "bad_validation_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błędne dane!"])},
    "bad_validation_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprawdź jeszcze raz wprowadzone dane w formularzu."])},
    "copy_contact_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopiowano adres!"])},
    "copy_contact_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail kontaktowy został skopiowany do Twojego schowka."])}
  },
  "Validation": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To pole nie może być puste!"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niewłaściwy format e-maila!"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numer powinien zawierać jedynie cyfry!"])}
  }
}