<template>
  <div class="col-12 col-lg-6 mb-2 tile-box">
    <div class="our-project border border-2 mx-2 my-3 tile-box-inside">
      <div class="d-flex flex-column h-100">
        <div class="align-self-start">
          <img :src="require(`@/assets/img/our-projects/${titleIcon}`)" loading="lazy" alt="Icon"
            class="float-start our-project-title_img">
          <span class="our-project-title">{{ $t(title) }}</span>
        </div>
        <div class="my-4 text-customization">
          {{ $t(desc) }}
        </div>
        <div class="align-self-end mt-auto reference-btn" v-if="url">
          <a :href="url" target="_blank" loading="lazy">
            <img src="@/assets/img/our-projects/PDF.svg" alt="PDF" class="me-2 ref-img">
            <div class="d-inline-block p-2 pe-4 border border-2 border-start-0 border-ds360 text-uppercase">
              {{ $t('OurProjects.reference') }}</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/css/ourProject/ourProject.css"

export default {
  props: {
    title: {
      type: String,
      required: true
    },
    titleIcon: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    },
    url: {
      type: String
    }
  }
}
</script>