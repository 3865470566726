<template>
  <form ref="contactus_form" method="post" @submit.prevent="sendEmail">
    <!-- Description and contac us headline for future purposes
        <p class="contact-description">
            {{ $t('ContactUs.description') }}
        </p>
        <h2 class="section-headline dark-headline noselect" v-animate>{{ $t('ContactUs.headline') }} </h2>
        -->

    <div class="row">
      <div class="col-12 col-lg-6">
        <input
          type="email"
          name="email"
          id="email"
          class="form-control"
          v-model="formData.email"
          @blur="v$.formData.email.$touch()"
          :placeholder="$t('ContactUs.placeholder_email')"
          required
        />
        <span class="error-message" v-if="v$.formData.email.$error">
          <p
            class="ps-3 pt-1"
            v-for="error in v$.formData.email.$errors"
            :key="error.$uid"
          >
            {{ $t(error.$message) }}
          </p>
        </span>
      </div>
      <div class="col-12 col-lg-6 mt-3 mt-lg-0">
        <input
          type="tel"
          name="phone"
          id="phone"
          class="form-control"
          v-model="formData.phone"
          @blur="v$.formData.phone.$touch()"
          :placeholder="$t('ContactUs.placeholder_phone')"
          required
        />
        <span class="error-message" v-if="v$.formData.phone.$error">
          <p
            class="ps-3 pt-1"
            v-for="error in v$.formData.phone.$errors"
            :key="error.$uid"
          >
            {{ $t(error.$message) }}
          </p>
        </span>
      </div>
    </div>
    <div class="form-group mt-3">
      <input
        type="text"
        name="subject"
        id="subject"
        class="form-control"
        v-model="formData.subject"
        @blur="v$.formData.subject.$touch()"
        :placeholder="$t('ContactUs.placeholder_subject')"
        required
      />
      <span class="error-message" v-if="v$.formData.subject.$error">
        <p
          class="ps-3 pt-1"
          v-for="error in v$.formData.subject.$errors"
          :key="error.$uid"
        >
          {{ $t(error.$message) }}
        </p>
      </span>
    </div>
    <div class="form-group mt-3">
      <textarea
        class="form-control"
        name="message"
        id="message"
        v-model="formData.message1"
        @blur="v$.formData.message1.$touch()"
        :placeholder="$t('ContactUs.placeholder_text')"
        required
      ></textarea>
      <span class="error-message" v-if="v$.formData.message1.$error">
        <p
          class="ps-3 pt-1"
          v-for="error in v$.formData.message1.$errors"
          :key="error.$uid"
        >
          {{ $t(error.$message) }}
        </p>
      </span>
    </div>
    <input class="w-100 mt-3" type="submit" :value="$t('ContactUs.send_btn')" />
  </form>
  <EmailAlert
    v-if="this.sendTrigger"
    :headline="alertHeader"
    :message="alertMessage"
    @closeAlertBox="closeAlert()"
  >
  </EmailAlert>
</template>

<script>
import emailjs from "@emailjs/browser";
import EmailAlert from "../alerts/EmailAlert.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, numeric, helpers } from "@vuelidate/validators";
import "../../assets/css/contactUsForm/contactUsForm.css";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      sendTrigger: false,
      alertHeader: "",
      alertMessage: "",
      emailSuccess: false,
      formData: {
        email: "",
        phone: "",
        subject: "",
        message1: "",
      },
    };
  },

  validations() {
    return {
      formData: {
        email: {
          required: helpers.withMessage("Validation.empty", required),
          email: helpers.withMessage("Validation.email", email),
        },
        phone: {
          required: helpers.withMessage("Validation.empty", required),
          numeric: helpers.withMessage("Validation.phone", numeric),
        },
        subject: {
          required: helpers.withMessage("Validation.empty", required),
        },
        message1: {
          required: helpers.withMessage("Validation.empty", required),
        },
      },
    };
  },

  methods: {
    async sendEmail() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.sendTrigger = true;
        this.alertHeader = "Alerts.bad_validation_headline";
        this.alertMessage = "Alerts.bad_validation_message";
        return;
      }

      emailjs
        .sendForm(
          "ds_360",
          "ds360_contactus",
          this.$refs.contactus_form,
          "XivHHYRNP8NpOLhBJ"
        )
        .then(
          (result) => {
            this.sendTrigger = true;
            this.alertHeader = "Alerts.success_contact_headline";
            this.alertMessage = "Alerts.success_contact_message";
            this.emailSuccess = true;
            console.log("SUCCESS!", result.text);
          },
          (error) => {
            this.sendTrigger = true;
            this.alertHeader = "Alerts.error_contact_headline";
            this.alertMessage = "Alerts.error_contact_message";
            console.log("FAILED...", error.text);
          }
        );
    },
    closeAlert(value) {
      this.sendTrigger = value;
      if (this.emailSuccess) {
        this.formData.email = "";
        this.formData.phone = "";
        this.formData.subject = "";
        this.formData.message1 = "";
        this.v$.formData.email.$reset();
        this.v$.formData.phone.$reset();
        this.v$.formData.subject.$reset();
        this.v$.formData.message1.$reset();
        this.emailSuccess = false;
      }
    },
    copyText() {
      navigator.clipboard.writeText(
        document.getElementById("contactEmail").innerText
      );
      this.sendTrigger = true;
      this.alertHeader = "Alerts.copy_contact_headline";
      this.alertMessage = "Alerts.copy_contact_message";
    },
  },

  components: { EmailAlert },
};
</script>
