<template>
  <section class="section-define-size our-team" id="team">
    <article class="container">
      <h2 class="section-headline" v-animate>{{ $t("OurTeam.headline") }}</h2>
      <div class="container-flex-ourteam team-content" v-animate>
        <div v-for="worker in workers" class="single-worker" :key="worker"> 
            <img
              class="worker-image"
              :src="worker.photo"
              :alt="worker.alt_photo"
              :id="worker.id"
              loading="lazy"
            />
            <hr class="worker-line" />
            <h3
              class="worker-name"
              id="show_name"
              v-html="$t(worker.name)"
            ></h3>
            <h4 class="worker-position" v-html="$t(worker.position)"></h4>
            <p class="worker-description" v-html="$t(worker.description)"></p>
          </div>
      </div>
    </article>
  </section>
</template>

<script>
/* eslint-disable */
import photo0 from "@/assets/img/workers/d_kaszynski.webp";
import photo18 from "@/assets/img/workers/w_jakuczun.webp";

import boss from "@/assets/img/workers/icons/boss.svg";

import { ref } from "vue";

export default {
  setup() {
    const open = ref(true);
  },
  data() {
    return {
      bosses: [0, 1],
      workers: [
        {
          id: 0,
          name: "OurTeam.worker0",
          position: "OurTeam.worker0_position",
          description: "OurTeam.worker0_desc",
          photo: photo0,
          alt_photo: "Daniel Kaszyński photo",
          icon: boss,
          alt_icon: "boss icon",
        },
        {
          id: 1,
          name: "OurTeam.worker1",
          position: "OurTeam.worker1_position",
          description: "OurTeam.worker1_desc",
          photo: photo18,
          alt_photo: "Wit Jakuczun photo",
          icon: boss,
          alt_icon: "boss icon",
        },
        // {
        //   id: 2,
        //   name: "OurTeam.worker2",
        //   position: "OurTeam.worker2_position",
        //   description: "OurTeam.worker2_desc",
        //   photo: photo4,
        //   alt_photo: "Maciej Fender photo",
        //   icon: simulate,
        //   alt_icon: "multi-agent simulation icon",
        // },
        // {
        //   id: 3,
        //   name: "OurTeam.worker3",
        //   position: "OurTeam.worker3_position",
        //   description: "OurTeam.worker3_desc",
        //   photo: photo5,
        //   alt_photo: "Dawid Dmitruk photo",
        //   icon: simulate,
        //   alt_icon: "multi-agent simulation icon",
        // },
        // {
        //   id: 4,
        //   name: "OurTeam.worker4",
        //   position: "OurTeam.worker4_position",
        //   description: "OurTeam.worker4_desc",
        //   photo: photo2,
        //   alt_photo: "Dawid Pieciul photo",
        //   icon: analytics,
        //   alt_icon: "analytics icon",
        // },
        // {
        //   id: 5,
        //   name: "OurTeam.worker5",
        //   position: "OurTeam.worker5_position",
        //   description: "OurTeam.worker5_desc",
        //   photo: photo1,
        //   alt_photo: "Urszula Białończuk photo",
        //   icon: analytics,
        //   alt_icon: "analytics icon",
        // },
        // {
        //   id: 6,
        //   name: "OurTeam.worker6",
        //   position: "OurTeam.worker6_position",
        //   description: "OurTeam.worker6_desc",
        //   photo: photo7,
        //   alt_photo: "Agnieszka Rabiej photo",
        //   icon: analytics,
        //   alt_icon: "analytics icon",
        // },
        // {
        //   id: 7,
        //   name: "OurTeam.worker7",
        //   position: "OurTeam.worker7_position",
        //   description: "OurTeam.worker7_desc",
        //   photo: photo3,
        //   alt_photo: "Jakub Kot photo",
        //   icon: analytics,
        //   alt_icon: "analytics icon",
        // },
        // {
        //   id: 8,
        //   name: "OurTeam.worker8",
        //   position: "OurTeam.worker8_position",
        //   description: "OurTeam.worker8_desc",
        //   photo: photo9,
        //   alt_photo: "Kamil Kulesza photo",
        //   icon: analytics,
        //   alt_icon: "analytics icon",
        // },
        // {
        //   id: 9,
        //   name: "OurTeam.worker9",
        //   position: "OurTeam.worker9_position",
        //   description: "OurTeam.worker9_desc",
        //   photo: photo15,
        //   alt_photo: "Ewelina Kędzior photo",
        //   icon: analytics,
        //   alt_icon: "analytics icon",
        // },
        // {
        //   id: 10,
        //   name: "OurTeam.worker10",
        //   position: "OurTeam.worker10_position",
        //   description: "OurTeam.worker10_desc",
        //   photo: photo6,
        //   alt_photo: "Bartłomiej Busłowski photo",
        //   icon: data,
        //   alt_icon: "data science icon",
        // },
        // {
        //   id: 11,
        //   name: "OurTeam.worker11",
        //   position: "OurTeam.worker11_position",
        //   description: "OurTeam.worker11_desc",
        //   photo: photo14,
        //   alt_photo: "Krzysztof Funkowski photo",
        //   icon: data,
        //   alt_icon: "data science icon",
        // },
        // {
        //   id: 12,
        //   name: "OurTeam.worker12",
        //   position: "OurTeam.worker12_position",
        //   description: "OurTeam.worker12_desc",
        //   photo: photo13,
        //   alt_photo: "Milena Święcka photo",
        //   icon: data,
        //   alt_icon: "data science icon",
        // },
        // {
        //   id: 13,
        //   name: "OurTeam.worker13",
        //   position: "OurTeam.worker13_position",
        //   description: "OurTeam.worker13_desc",
        //   photo: photo8,
        //   alt_photo: "Gracjan Ulianowski photo",
        //   icon: software,
        //   alt_icon: "software developer icon",
        // },
        // {
        //   id: 14,
        //   name: "OurTeam.worker14",
        //   position: "OurTeam.worker14_position",
        //   description: "OurTeam.worker14_desc",
        //   photo: photo11,
        //   alt_photo: "Łukasz Terpiłowski photo",
        //   icon: software,
        //   alt_icon: "software developer icon",
        // },
        // {
        //   id: 15,
        //   name: "OurTeam.worker15",
        //   position: "OurTeam.worker15_position",
        //   description: "OurTeam.worker15_desc",
        //   photo: photo16,
        //   alt_photo: "Piotr Romaniuk photo",
        //   icon: software,
        //   alt_icon: "software developer icon",
        // },
        // {
        //   id: 16,
        //   name: "OurTeam.worker16",
        //   position: "OurTeam.worker16_position",
        //   description: "OurTeam.worker16_desc",
        //   photo: photo10,
        //   alt_photo: "Magdalena Korecka photo",
        //   icon: graphic,
        //   alt_icon: "graphic designer icon",
        // },
        // {
        //   id: 17,
        //   name: "OurTeam.worker17",
        //   position: "OurTeam.worker17_position",
        //   description: "OurTeam.worker17_desc",
        //   photo: photo17,
        //   alt_photo: "Michał Ciupa photo",
        //   icon: software,
        //   alt_icon: "software developer icon",
        // },
        
      ],
      show: true,
      open,
      chosenId: 0,
      currentImage: photo0,
      worker_name: "OurTeam.worker0",
      position: "OurTeam.worker0_position",
      description: "OurTeam.worker0_desc",
    };
  },
  mounted() {
    // document.getElementById("0").classList.add("chosen-worker");
  },
  methods: {
    changeWorker(x) {
      document.getElementById(this.chosenId).classList.remove("chosen-worker");
      this.chosenId = x;
      document.getElementById(this.chosenId).classList.add("chosen-worker");

      this.worker_name = this.workers[x].name;
      this.position = this.workers[x].position;
      this.description = this.workers[x].description;
      this.currentImage = this.workers[x].photo;
    },
    switchOpen() {
      this.open = false;
      setTimeout(() => (this.open = true), 100);
    },
    workersId() {
      const array = this.workers.map((worker) => worker.id);
      this.bosses.forEach((boss) => {
        const index = array.indexOf(boss);
        array.splice(index, 1);
      });
      return array;
    },
  },
};
</script>
