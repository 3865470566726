<template>
    <section class="section-define-size our-projects" id="projects">
        <article class="container">
            <h2 class="section-headline section-headline-projects mb-3" v-animate>{{ $t('OurProjects.headline') }}</h2>
            <p class="section-description-projects mb-5" v-animate>{{ $t('OurProjects.description') }}</p>
            <div class="row justify-content-center" v-animate>
                <OurProject v-for="item in data" :key="item.id" :title="item.name_h" :titleIcon="item.icon"
                    :desc="item.name_d" :url="item.link"></OurProject>
            </div>
        </article>
    </section>
</template>

<script>
import OurProject from '../our-projects/OurProject.vue';



export default {
    components: {
        OurProject
    },
    data() {
        return {
            data: [
                { id: 1, name_h: 'OurProjects.project7_headline', name_d: 'OurProjects.project7_desc', link: '/20230123.pdf', icon: 'Data_analytics.svg' },
                { id: 2, name_h: 'OurProjects.project1_headline', name_d: 'OurProjects.project1_desc', link: '/20220328.pdf', icon: 'Trend_Prediction.svg' },
                { id: 3, name_h: 'OurProjects.project6_headline', name_d: 'OurProjects.project6_desc', link: '/20220805.pdf', icon: 'High_methane_gas.svg' },
                { id: 4, name_h: 'OurProjects.project2_headline', name_d: 'OurProjects.project2_desc', link: '/20220415.pdf', icon: 'Credit_scoring.svg' },
                { id: 5, name_h: 'OurProjects.project4_headline', name_d: 'OurProjects.project4_desc', link: '/20220513.pdf', icon: 'Carier_prices.svg' },
                { id: 6, name_h: 'OurProjects.project5_headline', name_d: 'OurProjects.project5_desc', link: '/20220803.pdf', icon: 'Guantitative_synthesis.svg' },
                { id: 7, name_h: 'OurProjects.project9_headline', name_d: 'OurProjects.project9_desc', link: '/20240111.pdf', icon: 'E_learning_moodle.svg' },
                { id: 8, name_h: 'OurProjects.project10_headline', name_d: 'OurProjects.project10_desc', link: '/20240112.pdf', icon: 'Machine_learning.svg' },
                { id: 9, name_h: 'OurProjects.project8_headline', name_d: 'OurProjects.project8_desc', icon: 'E_learning.svg' }
            ]
        }
    }
}
</script>