<template>

  <LoadingPage/>
  <NavHeader v-if="!$route.meta.hideNavbar" />
  <router-view class="app-content"/>

  <WebFooter v-if="!$route.meta.hideNavbar" />
  
</template>


<script>
  /* eslint-disable */
  import NavHeader from './components/NavHeader.vue'
  import WebFooter from './components/WebFooter.vue'
  import LoadingPage from './components/LoadingPage.vue'


  export default {
    name: "App", 
    components:{
      NavHeader,
      WebFooter,
      LoadingPage,
    }
  }
</script>
