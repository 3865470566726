<template>
    <div class="alert-bg">
        <div class="alert-box">
            <h2>{{$t(headline)}}</h2>
            <p class="alert-message">{{$t(message)}}</p>
            
            <button class="alert-btn" @click="closeAlert">
                OK
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'headline',
        'message'
    ],
    methods:
    {
        closeAlert()
        {
            this.$emit("closeAlertBox", false)
        }
    }
}
</script>