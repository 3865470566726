<!-- eslint-disable prettier/prettier -->
<template>
  <header class="menu" id="menu-nav">
    <div class="header-content">

      <router-link to="/">
        <img src="@/assets/img/logo/logo-solo.svg" fetchpriority="high" class="logo-mobile" alt="Logo DS360 company" />
      </router-link>

      <i class="fa fa-bars" @mouseup="showMenu()"></i>

      <nav class="row nav-content" :class="this.showMobileMenu ? 'open-menu' : 'closed-menu'">
        <div v-if="!isMobile()" class="col-2">
          <router-link to="/"><img class="logo-img" src="@/assets/img/logo/logo.svg"
              alt="Logo DS360 company" /></router-link>
        </div>

        <div class="col-5 empty-space"></div>

        <div class="col-1 nav-items" :class="{ active: showMobileMenu }" @click="showMobileMenu = !showMobileMenu">
          <RouterLink to="/#sectors" class="menu-link" id="nav_sectors">
            {{ $t("NavHeader.sectors") }}
          </RouterLink>
        </div>

        <div class="col-1 nav-items" :class="{ active: showMobileMenu }" @click="showMobileMenu = !showMobileMenu">
          <RouterLink to="/#projects" class="menu-link" id="nav_projects">
            {{ $t("NavHeader.projects") }}
          </RouterLink>
        </div>

        <div class="col-1 nav-items" :class="{ active: showMobileMenu }" @click="showMobileMenu = !showMobileMenu">
          <RouterLink to="/#technologies" class="menu-link" id="nav_technologies">
            {{ $t("NavHeader.technologies") }}
          </RouterLink>
        </div>

        <div class="col-1 nav-items" :class="{ active: showMobileMenu }" @click="showMobileMenu = !showMobileMenu">
          <RouterLink to="/#team" class="menu-link" id="nav_team">
            {{ $t("NavHeader.team") }}
          </RouterLink>
        </div>

        <div class="col-1 nav-items select" tabindex="1">
          <input class="selectopt" name="test" type="radio" id="opt1" value="pl" checked v-model="$i18n.locale" />
          <label for="opt1" class="option" :class="{ active: showMobileMenu }"
            @click="showMobileMenu = !showMobileMenu, changeLang('pl')">PL</label>

          <input class="selectopt" name="test" type="radio" id="opt2" value="en" v-model="$i18n.locale" />

          <label for="opt2" class="option" :class="{ active: showMobileMenu }"
            @click="showMobileMenu = !showMobileMenu, changeLang('en')">EN</label>

        </div>
      </nav>
    </div>
  </header>
</template>


<script>
export default {
  name: "NavHeader",
  data() {
    return {
      locales: ["pl", "en"],
      showNavbar: false,
      lastScrollPosition: 0,
      headers: [
        "sectors",
        "projects",
        "technologies",
        "team",
      ],
      currentSection: "",
      previousSection: "",
      showMobileMenu: false,
      starting_status: 0,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.ScrollObserver1);
    var prevScrollpos = window.pageYOffset;
    var x = document.documentElement.clientWidth || window.innerWidth;
    window.onscroll = function () {
      var currentScrollPos = window.pageYOffset;
      if (x > 800) {
        if (currentScrollPos > 750) {
          if (prevScrollpos > currentScrollPos) {
            document.getElementById("menu-nav").style.top = "0";
          } else {
            document.getElementById("menu-nav").style.top = "-150px";
          }
          prevScrollpos = currentScrollPos;
        }
      }
    }
  },

  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },

    ScrollObserver1() {
      let current;
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(
          (entry) => {
            if (entry.isIntersecting) {
              current = entry.target.getAttribute("id");
              this.currentSection = current;
              this.ActiveSection();
            }
          },
          { threshold: 0.9, rootMargin: "5rem" }
        );
      });

      document.querySelectorAll("section").forEach((section) => {
        observer.observe(section);
      });

      return current;
    },

    ActiveSection() {
      var mistake =
        this.headers.indexOf(this.currentSection) -
        this.headers.indexOf(this.previousSection);

      var mistake2 = Math.pow(mistake, 2);

      if (this.starting_status == 0) {
        this.currentSection = "about_us";
      }
      if (mistake2 == 4) {
        this.starting_status = 1;
        var index;
        if (mistake > 0) {
          // scroll down
          index = this.headers.indexOf(this.previousSection) + 1;

          if (index >= this.headers.length)
            index = this.headers.length - 1;

          this.currentSection = this.headers[index];
        } // scroll up
        else {
          index = this.headers.indexOf(this.previousSection) - 1;
          if (index <= 0) index = 0;
          this.currentSection = this.headers[index];
        }
        document
          .getElementById("nav_" + this.previousSection)
          .classList.remove("active-section");
        console.log(
          "prev:" +
          this.previousSection +
          " index: " +
          index +
          " curr: " +
          this.currentSection
        );
      }

      if (
        this.previousSection != this.currentSection &&
        (this.previousSection != "" || this.currentSection == null)
      ) {
        document
          .getElementById("nav_" + this.previousSection)
          .classList.remove("active-section");
      }

      if (this.currentSection == null)
        this.currentSection = this.headers[0];

      var el = document.getElementById("nav_" + this.currentSection);
      el.classList.add("active-section"); // jakiś problem z observerem
      this.previousSection = this.currentSection;
    },

    showMenu() {
      this.showMobileMenu = !this.showMobileMenu;
      /*this.showMobileMenu.toggle("disable-click");*/
    },
    changeLang(lang) {
      document.documentElement.setAttribute("lang", lang);
    }
  },
};
</script>