export default {
  "NavHeader": {
    "sectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects"])},
    "technologies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologies"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])}
  },
  "AboutUsShort": {
    "mainHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broader <br>perspective <br>on Data Science"])},
    "mainDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We deliver end-to-end Artificial Intelligence projects, covering the entire model lifecycle, from data acquisition and analysis to deployment and monitoring of the model in production. In our work, we combine the best practices from two worlds: the dynamic commercial sector and the rigorous academic standards. This allows us to provide solutions that not only meet business expectations but are also grounded in scientific methods."])}
  },
  "AboutUsLong": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are the team of experts with the passion for the entire IT and Ml industry. In our work we use modern technologies and nontrivial solutions. We advise companies from around the world, develop the software they work with and make them do it better. We do not sit idle, we are constantly acting and openning up for the innovation. We make a close-knit team and we want you to become one of us."])}
  },
  "OurSectors": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sectors in which we operate"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a team of experts specializing in Machine Learning solutions. We implement an end-to-end approach covering the full lifecycle of a Data Science project: from data collection, through processing, analysis, and modeling, to deployment and results monitoring. We use the latest technologies to deliver high-quality products to our clients. We provide consulting services to companies worldwide: we are dynamic and open to innovation."])},
    "sector1_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Implementing AI models in organizations."])},
    "sector1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We integrate advanced AI models into organizational processes, helping companies fully leverage the potential of artificial intelligence. Our services include customizing models to meet specific business needs, training teams, and providing ongoing support for monitoring and optimization. In our view, effective AI implementation must take into account people, processes, and technology."])},
    "sector2_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modeling"])},
    "sector2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We possess expertise in mathematical optimization, simulation, and predictive modeling, which are utilized by our clients in their business operations. Depending on the client's needs, we deliver tailor-made artificial intelligence solutions that support organizational planning processes (e.g., logistics optimization, What-if simulation scenarios, or demand forecasting for the retail sector)."])},
    "sector3_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Analysis"])},
    "sector3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We collect, process, and analyze data using algorithms, statistical tools, and Machine Learning techniques. Our approach includes data exploration, pattern identification, and creating predictive models that provide insights and recommendations to our clients. We support our clients in the transition from: data through accuracy to decision. We often transform raw production data into useful information that supports decision-making processes at every level of organizational operations."])},
    "sector4_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Quality"])},
    "sector4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projects in data quality maintenance focus on comprehensive processing and analysis of information, enabling our clients to concentrate on the most important aspects of their operations. We apply techniques such as data validation, cleansing, and enrichment to ensure data integrity and reliability. Through this work, our clients can rely on consistent, complete, and trustworthy data used in day-to-day management and strategic planning."])}
  },
  "OurProjects": {
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["References"])},
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our projects"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We turn experience into success of our clients."])},
    "number1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27"])},
    "number1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client projects"])},
    "number2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6"])},
    "number2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["research projects"])},
    "number3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30"])},
    "number3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mln budget"])},
    "crypto_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CryptoDS360"])},
    "crypto_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CryptoDS360 is a tool for analyzing your profits and loses. It allows you to track the activity of largest accounts as well as view a wide range of statistics."])},
    "crypto_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more"])},
    "project1_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development of a trend and prediction algorithm"])},
    "project1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A project related to developing software that uses algorithms for real estate valuation. As part of the project, algorithms for market trends and property price forecasting as a form of collateral (e.g., mortgage) were developed. The modeling work was conducted in Java, using Spring Data to create object-relational mapping."])},
    "project2_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development of a credit scoring model"])},
    "project2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project involved developing a credit scoring model for customers used by banks. As part of the project, we analyzed the predictive power of explanatory variables, along with the identification of anomalies in the data (e.g., issues of collinearity, nonlinearity, or interactions between variables). The developed model underwent an independent review before production implementation following the W KNF's Recommendation."])},
    "project3_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pest and mold incidence prediction model"])},
    "project3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project consisted of the implementation of a prediction model, which included a detailed analysis of the subject, the definition of a mathematical model used for multi-class classification and the implementation of the algorithm in Python."])},
    "project4_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migration of the credit scoring model"])},
    "project4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As part of the work, we developed an information system that allows the compilation of credit scoring algorithms developed in SAS into the PMML format standard. This standard enables integration with solutions created in other technologies, such as Python, R, or Julia. The processes of data transformation, model estimation, and forecasting were subject to compilation and transfer between standards."])},
    "project5_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting for the Ministry of Health of the Republic of Poland in the field of quantitative synthesis of statistical information"])},
    "project5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project carried out for the Ministry of Health of the Republic of Poland included the provision of consulting services in the field of quantitative synthesis of statistical information, as well as conceptual and substantive support. The consulting covered the area of the Health Needs Map – Systemic and Implementation Analysis Database."])},
    "project6_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model of long-term demand for the high-methane network gas"])},
    "project6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During the execution of the project, we identified the main determinants affecting gas demand. We developed a methodology for long-term demand forecasting and based on this, we developed a system that was implemented in R. We concluded the project by delivering a detailed forecast that utilizes the prepared system, baseline parameter values, and quantitative data."])},
    "project7_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert support in building a team and increasing competence in the area of predictive models (Data Analytics)"])},
    "project7_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the project, we focused on defining the profiles of key team members and the software necessary for data management and building predictive models. Based on these, we were able to determine development scenarios, create an implementation plan, and propose an appropriate timeline for the work being conducted."])},
    "project8_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A range of R&D projects and grants"])},
    "project8_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members of our team participated in many processes for acquiring and executing research projects funded by public sources. These projects included topics in operations research (i.e., simulation, optimization, forecasting) using modern machine learning methods in decision-making processes. The total budget of the projects in which we supported our clients exceeds 100 million PLN."])},
    "project9_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-learning modules on the Moodle platform"])},
    "project9_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The project aimed to create an e-learning environment and integrate thematic modules. Our team was responsible for deploying the Moodle platform onto the server and customizing it to meet the client's needs. We designed thematic boards and created original graphics, and interactive h5p applications that complemented the content developed by our expert. Subsequently, we integrated the modules into the platform and conducted training sessions to enable employees to navigate the platform independently."])},
    "project10_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development of a system supporting the monitoring of the course and early detection of relapses in affective disorders based on Artificial Intelligence algorithms"])},
    "project10_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our experts provided the client with specialized support in Machine Learning. They developed methods and algorithms used for monitoring the course and detecting relapses of bipolar affective disorders. They identified the main factors based on which it was possible to observe the progression and detection of the disease. Based on the accumulated knowledge and data, they developed a decision metamodel that controls the population profile model associated with each patient. They also provided support in evaluating the system's performance and in the production deployment."])},
    "project_expand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click for details"])}
  },
  "OurTechnologies": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Battlefield Simulation Models"])},
    "languages_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programing languages"])},
    "languages_others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as well as <br/> C, C#, LaTeX, JS, PHP, C++, Matlab, R, Kotlin, Julia, Fortran, CSS, HTML"])},
    "db_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databases"])},
    "db_others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as well as <br/> PostgreSQL, H2, mySQL, Microsoft SQL Server"])},
    "frameworks_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frameworks"])},
    "frameworks_others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["as well as <br/> Spring MVC, Spring Data, Jakarta EE, Junit 5, Django, Selenium, Cypres, Vue.js"])},
    "data_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Processing"])},
    "visualizations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizations"])},
    "forecast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forecast"])},
    "simulations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulations"])},
    "optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization"])},
    "sql": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SQL"])},
    "r": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])},
    "r_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R - dashboards"])},
    "python": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Python"])},
    "python_dashboards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Python - dashboards"])},
    "lib_machine_learning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machine learning libraries"])},
    "sym_monte_carlo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monte Carlo simulations"])},
    "sym_multi-agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-agent simulations"])},
    "prog_linear_int": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linear programming and integer programming"])},
    "opt_engines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optimization engines"])},
    "partnership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategic partnership"])},
    "partnership_scensei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scensei"])},
    "partnership_scensei_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are a strategic partner of the Swiss company Scensei GmbH, which operates in the defence software market. Together with Scensei, we are developing the OPTIMA product, which enables the analysis of generative scenarios for defensive operations through multi-agent simulations based on artificial intelligence models. These simulations allow for the analysis of interactions between ground, air, and defence and electronic warfare systems, making decisions within a command and control network."])},
    "partnership_new_scensei_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For many years, together with the Swiss company Scensei, we have been providing advanced software to support governments in making strategic decisions in the field of national defense. Our flagship solution, OPTIMA, is used to generate defense operation scenarios through multi-agent simulations based on AI models. We enable comprehensive analysis of interactions between <b>land</b>, <b>air</b>, and <b>electronic warfare and defense systems</b>, supporting key decision-making within the command and control network."])},
    "partnership_new_scensei_under": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTIMA is a trusted tool for over 40 government clients in Europe and beyond."])}
  },
  "OurTeam": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])},
    "experts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert Team"])},
    "operationals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operational Team"])},
    "worker0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daniel Kaszyński"])},
    "worker0_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Director"])},
    "worker0_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant in the area of analytical models with ten years of experience. He has supported the world's largest banks in the implementation of algorithms and risk management models. Daniel has been teaching analytical methods at SGH for many years."])},
    "worker1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wit Jakuczun"])},
    "worker1_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Board Advisor"])},
    "worker1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wit advises data science teams at the management layer. He specializes in combining operations research (constraint programming, mixed integer programming, and metaheuristics) with predictive models to deliver decision-support solutions."])},
    "worker2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maciej Fender"])},
    "worker2_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-agent simulations Cloud technologies"])},
    "worker2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maciej is a back-end developer specializing in multi-agent simulations. In business, he focuses on designing, creating, optimizing, and implementing software. Additionally, Maciej is interested in cloud solutions and administration."])},
    "worker3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dawid Dmitruk"])},
    "worker3_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-agent simulations Back-end development"])},
    "worker3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java developer with experience in the development of both web applications and multi-agent simulations. In his work, he pays special attention to the quality of the code and ensures that it is optimized."])},
    "worker4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dawid Pieciul"])},
    "worker4_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data analysis Team management"])},
    "worker4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A business analyst with experience in migrating data from a large IT system, and an enthusiast of IoT solutions and logic puzzles. Dawid, with his conceptual approach, can find optimal solutions to various problems."])},
    "worker5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urszula Białończyk"])},
    "worker5_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data analysis Machine learning"])},
    "worker5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The analyst specializing in big data analysis and predictive modelling. In her career, she has worked on issues related to medicine, transportation and banking. PhD candidate at the PAN working on medical database modelling."])},
    "worker6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agnieszka Rabiej"])},
    "worker6_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data analysis Machine learning"])},
    "worker6_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A business analyst collaborating with companies in the energy, media, and FMCG sectors. She specializes in comprehensive data analysis and modelling, utilizing ML and AI to support business processes."])},
    "worker7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakub Kot"])},
    "worker7_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data analysis Graphic design"])},
    "worker7_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A practitioner in the e-commerce industry, he has spent years working extensively on the modernization of sales processes from the perspective of a commercial client. On the workshop side, Kuba is also a graphic designer, experienced in the field of creating usable materials, UX and UI design."])},
    "worker8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamil Kulesza"])},
    "worker8_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data analysis Machine learning"])},
    "worker8_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data analyst specializing in information processing, building analytical models, and automating processes. He is interested in practical applications of statistics and machine learning."])},
    "worker9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ewelina Kędzior"])},
    "worker9_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data analysis Machine learning"])},
    "worker9_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A programmer in Python and C#. She specializes in comprehensive data analysis and modelling. Ewelina is also a data analyst with an interest in the development of optimization algorithms."])},
    "worker10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bartłomiej Busłowski"])},
    "worker10_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data migration Back-end development"])},
    "worker10_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bartłomiej is a front-end developer with extensive experience as a database programmer for a large IT system and as a business analyst. He has also led a small development team. His versatile technical and analytical skills enable him to create intuitive and efficient front-end solutions."])},
    "worker11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krzysztof Funkowski"])},
    "worker11_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data migration Back-end development"])},
    "worker11_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Full-Stack developer and database programmer with extensive experience as a database developer for a large IT system. Krzysztof also has experience in creating web applications."])},
    "worker12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milena Święcka"])},
    "worker12_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data migration Back-end development"])},
    "worker12_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Milena is a Data Governance specialist with extensive experience as a database programmer for a large IT system and as a business analyst. She also has experience in front-end programming. In her applications, Milena focuses on the quality of the software being developed."])},
    "worker13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracjan Ulianowski"])},
    "worker13_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front-end development Back-end development"])},
    "worker13_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gracjan is involved in the process of software development and implementation. He gained experience in creating optimization solutions for banking clients. He is interested in IoT-related topics."])},
    "worker14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Łukasz Terpiłowski"])},
    "worker14_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-end development Front-end development"])},
    "worker14_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Full-Stack developer with experience in React, JavaScript, Java, and Python. Łukasz has worked on adapting a large language model (LLM) for specific applications."])},
    "worker15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piotr Romaniuk"])},
    "worker15_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back-end development Machine learning"])},
    "worker15_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piotr has experience in multiple programming languages (Java, Python, C#, and C++). He has worked with backend technologies, machine learning, and computer game engines."])},
    "worker16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magdalena Korecka"])},
    "worker16_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphic design Front-end development"])},
    "worker16_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A graphic designer and front-end developer. She has experience in creating websites and designing business and advertising materials."])},
    "worker17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Michał Ciupa"])},
    "worker17_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front-end development"])},
    "worker17_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A programmer with experience in TypeScript, JavaScript, and MongoDB. Michał has been involved in developing web applications."])}
  },
  "ContactUs": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you need help in development of your business? Are you looking for interesting ventures? Would you like to get to know us better? Contact us!"])},
    "company_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Head office:"])},
    "company_headline_mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ul. Żurawia 71 <br/> 15-540 Bialystok <br/> Poland"])},
    "company_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone:"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail:"])},
    "placeholder_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail..."])},
    "placeholder_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your contact number..."])},
    "placeholder_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the subject of the message..."])},
    "placeholder_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your message..."])},
    "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "recruitment_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to join us?"])},
    "recruitment_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are actively recruiting."])},
    "recruitment_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruitment offer"])}
  },
  "WebFooter": {
    "policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DS360 sp. z o. o. - All rights reserved"])}
  },
  "WelcomeRecruitment": {
    "mainHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join<br>DS360!"])},
    "mainDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."])}
  },
  "WhatWeOffer": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do we offer?"])},
    "offer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interesting international projects (i.a. optimization, multi-agent simulations)"])},
    "offer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooperation with scientists from the largest Polish universities"])},
    "offer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid internship and flexible working hours"])},
    "offer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opportunities to learn and grow"])}
  },
  "WhatWeWant": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do we require?"])},
    "want1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge of Javy or Pythona"])},
    "want2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interest in the Machine Learning related topics"])},
    "want3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge of GIT"])},
    "want4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open mind and willingness to learn"])}
  },
  "SendCV": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Determined?"])},
    "desc1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to start working with us and you are interested in joint development? Fill out the form and email us or send your resume to the provided email. We will respond to you soon."])},
    "placeholder_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your e-mail..."])},
    "placeholder_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your contact number..."])},
    "placeholder_subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the subject of your message..."])},
    "placeholder_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your message..."])},
    "send_btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach CV"])}
  },
  "WelcomeCrypto": {
    "mainHeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CryptoDS360"])},
    "mainDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CryptoDS360 will allow you to better analyze, gether knowledge and invest in the most popular cryptocurrencies. Track the profit and loss balance of your investments. View the latest proprietary and trusted analysis of the largest portfolios."])}
  },
  "CryptoScreens": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check out how it looks!"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanks to its clear form, CryptoDS360 allows users to easily switch between sections. It is an easy-to-use application that will come in handy for any person who cares about constant control of their cryptocurrency wallet."])},
    "swipe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swipe to see more"])},
    "turn_on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on dark mode"])},
    "turn_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off dark mode"])}
  },
  "CryptoFeatures": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features of the application"])},
    "feature1_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price graphs"])},
    "feature1_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The latest price data on selected cryptocurrencies"])},
    "feature2_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account transactions"])},
    "feature2_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List of transactions of selected accounts"])},
    "feature3_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A chart of the day's transactions"])},
    "feature3_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of transactions performed on a given day"])},
    "feature4_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original blockchain analysis"])},
    "feature4_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conversion of raw blockchain data into a transparent form"])},
    "feature5_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Original API"])},
    "feature5_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our experts provide an accurate and reliable source of data"])}
  },
  "ErrorPage": {
    "headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page you're looking for doesn't exist."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Press the button below to return to the home page."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home page"])}
  },
  "Alerts": {
    "success_contact_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message sent!"])},
    "success_contact_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, for your e-mail. Soon our consultant will reply to your message :)"])},
    "error_contact_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error has occurred!"])},
    "error_contact_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, there was an error while sending the message."])},
    "success_sendcv_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you, for participating in the recruitment. Expect to hear back from you ;)"])},
    "bad_validation_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid data!"])},
    "bad_validation_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the data entered in the form again."])},
    "copy_contact_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adress copied!"])},
    "copy_contact_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact e-mail was copied to your clipboard."])}
  },
  "Validation": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field cannot be empty!"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect e-mail format!"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number should contain only digits!"])}
  }
}