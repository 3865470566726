<template>
    <section class="section-define-size about-us-short main-section noselect">
        <article class="container container-flex flex-gap">

            <div class="col-6 main-head">
                <h1 class="main-headline" v-html="$t('AboutUsShort.mainHeadline')">
                </h1>
                <p class="main-description">
                    {{ $t('AboutUsShort.mainDescription') }}
                </p>
            </div>

            <video v-if="!isSafari()" class="col-6 video-about" autoplay muted loop playsinline noremoteplayback
                disablepictureinpicture>
                <source src="@/assets/animations/intro_new.webm" type='video/webm' />
            </video>
            <img v-else class="col-6 video-about" src="@/assets/animations/ds_intro_safari.png">

        </article>
    </section>
</template>

<script>
export default {

    methods: {
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },

        isSafari() {
            return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        }
    }
}
</script>