<template>
    <section class="section-define-size using-technologies noselect" id="technologies">
        <article class="container">
            <h2 class="section-headline" v-animate>{{ $t('OurTechnologies.headline') }}</h2>
            <div class="column border-custom" v-animate>
                <div class="col-12 technologies-partnership text-center" id="data_analytics"> <!--col-xl-6 col-xxl-7-->
                    <span class="mb-4 h3 me-xl-3">{{ $t("OurTechnologies.partnership") }}</span>
                    <div class="d-block d-xl-none"></div>
                    <a href="https://www.scensei.com/" target="_blank" loading="lazy" class="scensei-logo-link">
                        <img class="mb-4 mt-3 h3" src="@/assets/img/technology/scensei-logo.webp" alt="Scensei">
                    </a>
                    <p class="mb-4 small light" v-html="$t('OurTechnologies.partnership_new_scensei_desc')"></p>
                    <p class="mb-4 small light">{{ $t("OurTechnologies.partnership_new_scensei_under") }}</p>
                    <video class="video-scensei" loading="lazy" autoplay muted loop playsinline noremoteplayback
                        disablepictureinpicture>
                        <source src="@/assets/animations/scensei.mp4" type='video/mp4' />
                        <img src="@/assets/img/technology/partnership.svg">
                    </video>
                </div>
            </div>

            <!-- <div class="technologies-document">
                <div v-for="d in data" :key="d.id" class="technologies-document-display">
                    <h4>{{ $t(d.title) }}</h4>
                    <ul class="small technologies-document-list">
                        <li v-for="tech in d.tech" :key="tech.id">
                            <span class="semibold">{{ $t(tech.main) }}</span>
                            <span v-if="tech.desc">
                                <span class="semibold">:</span> <br class="line-break"> <span class="light">{{ tech.desc }}</span>
                            </span>
                        </li>
                    </ul>
                </div>
            </div> -->
        </article>
    </section>
</template>

<script>

export default {
    data() {
        return {
            data: [
                {
                    id: 0, title: "OurTechnologies.data_processing",
                    tech: [
                        { id: 0, main: "OurTechnologies.sql" },
                        { id: 1, main: "OurTechnologies.r", desc: "Tidyverse" },
                        { id: 2, main: "OurTechnologies.python", desc: "pandas, Polars, DuckDB, NumPy, PyTorch, JAX" }
                    ]
                },
                {
                    id: 1, title: "OurTechnologies.visualizations",
                    tech: [
                        { id: 0, main: "OurTechnologies.python", desc: "matplotlib , seaborn, plotly" },
                        { id: 1, main: "OurTechnologies.python_dashboards", desc: "Dash, PyShiny" },
                        { id: 2, main: "OurTechnologies.r_dashboards", desc: "Shiny" }
                    ]
                },
                {
                    id: 2, title: "OurTechnologies.forecast",
                    tech: [
                        { id: 0, main: "OurTechnologies.lib_machine_learning", desc: "scikit-learn, PyTorch, XGBoost, LightGBM, Hugging Face" }
                    ]
                },
                {
                    id: 3, title: "OurTechnologies.simulations",
                    tech: [
                        { id: 0, main: "OurTechnologies.sym_monte_carlo", desc: "NumPy, JAX, NumPyro" },
                        { id: 1, main: "OurTechnologies.sym_multi-agent", desc: "Java" }
                    ]
                }, {
                    id: 4, title: "OurTechnologies.optimization",
                    tech: [
                        { id: 0, main: "OurTechnologies.prog_linear_int", desc: "OR-Tools, PyOptInterface" },
                        { id: 1, main: "OurTechnologies.opt_engines", desc: "HiGHS, CP-SAT, GSCIP" }
                    ]
                }
            ],
        }
    }
}
</script>