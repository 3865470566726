<template>
    <section class="section-define-size our-sectors sectors-modify" id="sectors">
        <article>
            <h2 class="section-headline section-headline-projects mb-3">{{ $t('OurSectors.headline') }}</h2>
            <p class="section-description-projects mb-5">{{ $t('OurSectors.description') }}</p>
            <div class="sectors-container ">
                <div class="single-sector-box noselect" v-for="sector in sectors" :key="sector.id">
                    <img class="sector-image" :src="sector.img" :alt="sector.h3" loading="lazy" />
                    <h3 class="single-sector-headline">
                        {{ $t(sector.h3) }}
                    </h3>
                    <p class="single-sector-description">
                        {{ $t(sector.desc) }}
                    </p>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
/* eslint-disable */

import sector1 from '@/assets/img/sectors/ai-models.svg';
import sector2 from '@/assets/img/sectors/modeling.svg';
import sector3 from '@/assets/img/sectors/data-analysis.svg';
import sector4 from '@/assets/img/sectors/data-quality.svg';

export default
    {
        data() {
            return {
                sectors: [
                    { id: 1, h3: 'OurSectors.sector1_headline', desc: 'OurSectors.sector1_desc', img: sector1 },
                    { id: 2, h3: 'OurSectors.sector2_headline', desc: 'OurSectors.sector2_desc', img: sector2 },
                    { id: 3, h3: 'OurSectors.sector3_headline', desc: 'OurSectors.sector3_desc', img: sector3 },
                    { id: 4, h3: 'OurSectors.sector4_headline', desc: 'OurSectors.sector4_desc', img: sector4 },
                ]
            }
        }
    }  
</script>