import { computed, createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import './assets/css/main.css'
import router from './router'


import {MotionPlugin, useMotion} from '@vueuse/motion'
import './registerServiceWorker'

const isMobile = computed(() => innerWidth < 649)

const app = createApp(App)

app.use(router)
app.use(i18n)


app.use(MotionPlugin)

app.directive('animate', {
    created (el, binding) {
        const initial = binding.value ?? { opacity: 0, y: 100 }
        const visibleOnce = {
            opacity: 1,
            x: 0,
            y: 0
        }

        useMotion(el, computed(() => ({
            visibleOnce,
            initial: isMobile.value
                ? visibleOnce 
                : initial
        })))
    }
})

app.mount('#app')


